var ScrollTo = /** @class */ (function () {
    function ScrollTo(_anchorSelector, _elementSelector) {
        this._anchorSelector = _anchorSelector;
        this._elementSelector = _elementSelector;
        this._anchor = document.getElementById(this._anchorSelector);
        this._element = document.getElementById(this._elementSelector);
    }
    ScrollTo.prototype.Register = function () {
        var _this = this;
        if (this._anchor) {
            this._anchor.addEventListener("click", function (e) {
                e.preventDefault();
                _this._element.scrollIntoView({
                    block: "start",
                    behavior: "smooth",
                });
            });
        }
    };
    return ScrollTo;
}());
export default ScrollTo;