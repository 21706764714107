var ContactForm = /** @class */ (function () {
    function ContactForm(_multiSelector) {
        var _this = this;
        this._multiSelector = _multiSelector;
        var selectedElements = _multiSelector.Select();
        var aKeys = Object.keys(selectedElements).sort();
        var bKeys = Object.keys(_multiSelector.SelectorsObject).sort();
        if (JSON.stringify(aKeys) === JSON.stringify(bKeys)) {
            this._props = selectedElements;
        }
        this._props.BaseElement.addEventListener("submit", function (e) {
            if (_this.validate()) {
                return true;
            }
            else {
                e.preventDefault();
                return false;
            }
        });
        this._props.MessageInput.addEventListener("focusin", function (e) {
            var target = e.target;
            target.classList.add("is-focused");
        });
        this._props.MessageInput.addEventListener("focusout", function (e) {
            var target = e.target;
            if (!target.value) {
                target.classList.remove("is-focused");
            }
        });
        this._props.EmailInput.addEventListener("focusin", function (e) {
            var target = e.target;
            target.classList.add("is-focused");
        });
        this._props.EmailInput.addEventListener("focusout", function (e) {
            var target = e.target;
            if (!target.value) {
                target.classList.remove("is-focused");
            }
        });
    }
    ContactForm.prototype.validate = function () {
        if (!this.validateMessage()) {
            this._props.MessageInput.style.border = "2px solid #b51421";
            alert("Vyplňte prosím obsah zprávy.");
            return false;
        }
        if (!this.validateEmail()) {
            this._props.EmailInput.style.border = "2px solid #b51421";
            alert("Vyplňte prosím email.");
            return false;
        }
        return true;
    };
    ContactForm.prototype.validateEmail = function () {
        var regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        var isValid = regex.test(String(this._props.EmailInput.value).toLowerCase());
        isValid
            ? (this._props.EmailInput.style.border = "1px solid #525252")
            : (this._props.EmailInput.style.border = "2px solid #b51421");
        return isValid;
    };
    ContactForm.prototype.validateMessage = function () {
        var isValid = this._props.MessageInput.value;
        isValid
            ? (this._props.MessageInput.style.border = "1px solid #525252")
            : (this._props.MessageInput.style.border = "2px solid #b51421");
        return isValid;
    };
    return ContactForm;
}());
export default ContactForm;