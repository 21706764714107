var MultiSelector = /** @class */ (function () {
    function MultiSelector(_selectorsObject) {
        this._selectorsObject = _selectorsObject;
    }
    Object.defineProperty(MultiSelector.prototype, "SelectorsObject", {
        get: function () {
            return this._selectorsObject;
        },
        enumerable: true,
        configurable: true
    });
    MultiSelector.prototype.Select = function () {
        var _this = this;
        var elementObj = Object.create(this._selectorsObject);
        Object.keys(this._selectorsObject).map(function (key) {
            elementObj[key] = document.querySelector(_this._selectorsObject[key]);
        });
        return elementObj;
    };
    return MultiSelector;
}());
export default MultiSelector;