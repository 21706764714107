import ScrollTo from "./ScrollTo";
import ContactForm from "./ContactForm";
import MultiSelector from "./MultiSelector";
/**
 * SCROLL TO
 */
var scrollToContent = new ScrollTo("js-scrollto-content-anchor", "js-content-element");
scrollToContent.Register();
var scrollToContacts = new ScrollTo("js-scrollto-contacts-anchor", "js-contact-element");
scrollToContacts.Register();
var scrollToContacts2 = new ScrollTo("js-scrollto-form-anchor", "js-contact-element");
scrollToContacts2.Register();
var scrollToTop = new ScrollTo("js-scrollto-top-anchor", "js-top-element");
scrollToTop.Register();
/**
 * CONTACT FORM
 */
var contactForm = new ContactForm(new MultiSelector({
    BaseElement: "#contactForm",
    MessageInput: "#message",
    EmailInput: "#email",
    SubmitButton: "#submit",
}));